<template>
  <div class="container">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="earth"></b-icon>
          <span>{{ $ml.get('titles_proxies_buy') }}</span>
        </h4>
      </div>
    </div>
    <form @submit.prevent="submit" novalidate>
      <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field
              :label="$ml.get('proxies_mark') + ' *'"
              :message="'mark' in errors ? errors['mark'] : ''"
              :type="'mark' in errors ? 'is-danger' : ''" class="plate">
            <b-input v-model="form.mark" icon="tag" :placeholder="$ml.get('proxies_mark_example')"></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field
              :label="$ml.get('proxies_country') + ' *'"
              :message="'country' in errors ? errors['country'] : ''"
              :type="'country' in errors ? 'is-danger' : ''" class="plate">
            <b-select v-model="form.country" icon="earth" expanded placeholder="Страна">
              <option v-for="(option, property) in proxy_countries" :value="property" :key="property">
                {{ option }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field
              :label="$ml.get('proxies_period') + ' *'"
              :message="'period' in errors ? errors['period'] : ''"
              :type="'period' in errors ? 'is-danger' : ''" class="plate">
            <b-select v-model="form.period" icon="earth" expanded>
              <option v-for="(property) in [30, 60, 90]" :value="property" :key="property">
                {{ property }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-6">
          <b-notification
              type="is-warning is-light"
              aria-close-label="Close notification"
              role="alert">
            {{ $ml.get('proxies_not365') }}
          </b-notification>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <b-button :disabled="!isValid" type="is-success" outlined native-type="submit">{{ $ml.get('proxies_buy_for') }}
            {{ 2 * form.period / 30 }}€
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import router from "@/router";

export default {
  name: "Create",
  data() {
    return {
      form: {
        mark: '',
        country: 'ru',
        period: 30,
      },
      loading: false,
      errors: [],
    }
  },
  computed: {
    isValid() {
      return this.form.country.length > 0 && this.form.mark.length > 0;
    },
    proxy_countries() {
      if (this.form.is_mobile) {
        return this.$store.getters['data/betexy_proxy_countries']
      }
      return this.$store.getters['data/proxy_countries']
    }
  },
  methods: {
    submit: function () {
      if (confirm(this.$ml.get('proxies_buy_confirm'))) {
        this.loading = true
        this.errors = []
        Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'proxy/buy', {proxy: this.form}).then(resp => {
          this.flashMessage.success({
            title: this.$ml.get('common_request_success'),
          })
          router.push('/proxies')
        }).catch(err => {
          if (err.response.status === 400) {
            this.errors = err.response.data.result
          } else {
            this.flashMessage.error({
              title: this.$ml.get('proxies_no_available_title'),
              message: this.$ml.get('proxies_no_available_text'),
            })
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_proxies_buy')
    }
  }
}
</script>

<style scoped>
</style>